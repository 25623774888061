import React from "react";

import GlobalLayout from "components/page/GlobalLayout";
import Helmet from "react-helmet";
import { StaticQuery, graphql } from "gatsby";
import DevicePageTemplate from "components/common/templates/DevicePageTemplate";
import bulletVideo from "components/cameras/mp4/lpr-video.hevc.mp4";
import bulletVideoBackup from "components/cameras/mp4/backups/lpr-video.mp4";

export default function BulletCameras() {
  const renderContent = data => {
    const pageData = {
      headerSection: {
        preTitle: "Bullet cameras",
        title: "Get Long Range Visibility in a Ruggedized Form Factor",
        mobileTitle: "Get Long Range Visibility",
        description:
          "Protect your organization with cloud-based bullet security cameras. Capture detailed footage at range and modernize your physical security with remote access, onboard analytics, and enterprise-grade performance and reliability.",
        image: data.headerImage,
        button1: {
          text: "Compare Cameras",
          path: "/cameras/compare-cameras/",
        },
      },
      cardSection: {
        title: "Explore Bullet Security Cameras",
        cards: [
          {
            image: data.device1,
            name: "R500",
            description:
              "8MP Bullet with Telephoto Zoom ideal for ultra-long-distance visibility and analytics",
            link: "/cameras/bullet-cameras/r500/",
            flex: 2,
          },
          {
            image: data.device2,
            name: "R510",
            description:
              "8MP Bullet with Optical Zoom ideal for powerful analytics and long-distance visibility",
            link: "/cameras/bullet-cameras/r510/",

            flex: 2,
          },
        ],
      },

      benefitsSection: {
        title: "Deploy Modern Technology in a Classic Form Factor",
        items: [
          {
            image: data.benefit1,
            title: "Long-Range Visibility & Analytics",
            description:
              "Capture detailed footage at range and enable powerful AI analytics at longer distances. Monitor entryways, parking lots, and more with optical zoom and 4K video.",
          },
          {
            image: data.benefit2,
            title: "Powerful Onboard Processing",
            description:
              "Get crystal-clear footage and lightning-fast processing right out of the box. Footage is analyzed directly on the camera to save time, optimize bandwidth, and accelerate live alerts. ",
          },
          {
            image: data.benefit3,
            title: "Ruggedized Form Factor",
            description:
              "All the advantages of an intelligent cloud camera in a ruggedized, durable form factor. Get reliable coverage in a housing built to withstand the elements.",
          },
        ],
      },
      specSection: {
        title: "Capture with Incredible Clarity",
        image: data.specImage,
        specs: [
          {
            icon: data.icon1,
            title: "Crystal Clear",
            subTitle: "Video Resolution",
          },
          {
            icon: data.icon2,
            title: "8.3MP",
            subTitle: "Image Sensor",
          },
          {
            icon: data.icon3,
            title: "Motorized Zoom",
            subTitle: "Optical & Telephoto",
          },
          {
            icon: data.icon4,
            title: "IP66",
            subTitle: "Included Onboard",
          },
          {
            icon: data.icon5,
            title: "AI Analytics",
            subTitle: "Included Onboard",
          },
          {
            icon: data.icon6,
            title: "10-Year",
            subTitle: "Warranty",
          },
        ],
      },
      exploreSection: {
        cards: [
          {
            image: data.recommend1,
            name: "Dome Cameras",
            description:
              "Modern enterprise performance with edge-based analytics ",
            link: "/cameras/dome-cameras/",
            flex: 3,
          },
          {
            image: data.recommend2,
            name: "Fisheye Cameras",
            description:
              "Immersive coverage and visibility with minimal camera placement",
            link: "/cameras/fisheye-cameras/",
            flex: 3,
          },
          {
            image: data.recommend3,
            name: "Multisensor Cameras",
            description: "One powerful camera for complete coverage",
            link: "/cameras/multisensor-cameras/",
            flex: 3,
          },
        ],
      },
    };
    return (
      <GlobalLayout color="var(--gradient-light)">
        <Helmet>
          <title>Rhombus Bullet Cameras - Enterprise Video Surveillance</title>
          <meta
            name="description"
            content="Learn about Rhombus' bullet security cameras for commercial businesses, education, healthcare, real estate, and more."
          />
        </Helmet>
        <DevicePageTemplate
          data={pageData}
          video={bulletVideo}
          videoBackup={bulletVideoBackup}
          deviceType="Camera"
          recommend
          reverse
          image={data.device1}
        />
      </GlobalLayout>
    );
  };
  const query = graphql`
    query {
      headerImage: file(
        relativePath: {
          eq: "components/cameras/bullet-cameras/img/bullet-header-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 100
            placeholder: BLURRED
          )
        }
      }
      device1: file(
        relativePath: {
          eq: "components/cameras/bullet-cameras/img/r500-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 100
            placeholder: BLURRED
          )
        }
      }
      device2: file(
        relativePath: { eq: "components/cameras/img/bullet-explore-min.png" }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 100
            placeholder: BLURRED
          )
        }
      }
      benefit1: file(
        relativePath: {
          eq: "components/cameras/bullet-cameras/img/license-plates-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 100
            placeholder: BLURRED
          )
        }
      }
      benefit2: file(
        relativePath: {
          eq: "components/cameras/bullet-cameras/img/r500-detection-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 100
            placeholder: BLURRED
          )
        }
      }
      benefit3: file(
        relativePath: {
          eq: "components/cameras/bullet-cameras/img/r500-rain-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 100
            placeholder: BLURRED
          )
        }
      }
      specImage: file(
        relativePath: {
          eq: "components/sensors/environment-monitoring/updated/img/spec-image-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 100
            placeholder: BLURRED
          )
        }
      }
      icon1: file(relativePath: { eq: "components/common/icons/4K.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      icon2: file(relativePath: { eq: "components/common/icons/sensor.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      icon3: file(relativePath: { eq: "components/common/icons/vision.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      icon4: file(relativePath: { eq: "components/common/icons/weather.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      icon5: file(
        relativePath: { eq: "components/common/icons/learning.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      icon6: file(
        relativePath: { eq: "components/common/icons/certification.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      recommend1: file(
        relativePath: { eq: "components/cameras/img/dome-explore-min.png" }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 100
            placeholder: BLURRED
          )
        }
      }
      recommend2: file(
        relativePath: { eq: "components/cameras/img/fisheye-explore-min.png" }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 100
            placeholder: BLURRED
          )
        }
      }
      recommend3: file(
        relativePath: { eq: "components/cameras/img/r600-card-min.png" }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 100
            placeholder: BLURRED
          )
        }
      }
    }
  `;

  return <StaticQuery query={query} render={renderContent} />;
}
